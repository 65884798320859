import { Box, Typography, Button, Grid, Snackbar, Alert, TableContainer, Paper, Table, TableHead, TableBody, TableCell, TableRow } from "@mui/material";
import { Colors } from "../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../store/store";
import { CreateaHealthAssesment, GetHealthAssesment } from "../../../store/survey/survey-actions";
import surveySlice from "../../../store/survey/survey-slice";
import { IUser } from "../survey-form/survey-form";
import { useEffect, useState } from "react";
import LinearProgress from '@mui/material/LinearProgress';

export interface ISurveyStartProps {
    // setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export interface IHealthAssesment {
    id: number | null,
    userId: number | null,
    startedOn: Date | null,
    completedOn: Date | null
}

export const SurveyStart: React.FunctionComponent<ISurveyStartProps> = (props) => {
    // const { setCurrentPage } = props;
    const dispatch: any = useDispatch();
    const userData: IUser = useSelector((state: RootState) => state.loginModal.userData)
    const open = useSelector((state: RootState) => state.survey.SnackbarStartPage)
    const healthAssesment = useSelector((state: RootState) => state.survey.HealthAssesment)
    const healthAssesments = useSelector((state: RootState) => state.survey.AllHealthAssessments)
    // console.log(healthAssesments)
    const [healthAssesmentId, sethealthId] = useState(healthAssesment.id)

    useEffect(() => { sethealthId(healthAssesment.id) }, [healthAssesment])
    useEffect(() => {

    }, [])

    const handleStartNewAssesment = async () => {
        if (userData.id != undefined) {
            // dispatch(surveySlice.actions.setAnswers([]))
            await dispatch(CreateaHealthAssesment(fetch, userData.id))
            dispatch(surveySlice.actions.setSnackbarStartPage(false))
        }
        else
            console.error("User data is corrupted or missing completely");
    }

    const handleContinueWithLatestHealthAssesment = () => {
        if (userData.id != undefined) {
            // dispatch(GetHealthAssesment(fetch, userData.id))
            dispatch(surveySlice.actions.setSnackbarStartPage(false))
        }
        else
            console.error("User data is corrupted or missing completely");
    }

    // Function to handle Snackbar close
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(surveySlice.actions.setSnackbarStartPage(false))
        // setOpen(false);
    };

    useEffect(() => {
        // console.log('SKIDAM HEALTHASSesm', userData)
        if (userData.id !== undefined) {
            // console.log(healthAssesment)
            dispatch(GetHealthAssesment(fetch, userData.id))
        }
    }, [userData.id])


    const defaultDate = new Date('0001-01-01T00:00:00');
    const completedOnDate = healthAssesment?.completedOn ? new Date(healthAssesment?.completedOn) : null;

    // const isDisabled =
    //     healthAssesmentId === null ||
    //     !completedOnDate ||
    //     completedOnDate.getTime() !== defaultDate.getTime();


    const renderContinueButton = (ha: IHealthAssesment) => {
        // console.log('datum', ha.completedOn && new Date(ha.completedOn))
        const buttonText =
            ha.completedOn === null
                ? 'NASTAVI'
                : 'VIDI';
        return (
            <Button
                size="small"
                sx={{
                    backgroundColor: Colors.MMYellow1,
                    color: 'black',
                    transform: 'scale(0.85)',
                    border: '1px solid lightgrey',
                    mx: 0,
                    '&:hover': {
                        backgroundColor: 'black',
                        borderColor: 'gray',
                        color: 'white',
                    },
                }}
                // disabled={isDisabled}
                type="submit"
                variant="contained"
                fullWidth
                onClick={() => {
                    dispatch(surveySlice.actions.setCurrentPage(1));
                    handleContinueWithLatestHealthAssesment();
                    dispatch(surveySlice.actions.setHealthAssesment(ha))
                }}
            >
                {buttonText}
            </Button>
        );
    };



    return (

        <Box
            sx={{
                minHeight: '80vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                px: 0,
            }}
        >
            <Typography
                variant="h5"
                component="p"
                sx={{ pb: 2 }}
            >
                Dobrodošli u našu anketu!
            </Typography>
            <Typography
                variant="body1"
                component="p"
                sx={{ pb: 4, maxWidth: '600px' }}
            >
                Vaše informacije će nam pomoći da bolje razumemo vaše zdravstvene potrebe i usmerimo vas na odgovarajuće preglede. Anketa će vam oduzeti oko 10 minuta.
            </Typography>
            {/* <Typography
                variant="h4"
                component="h2"
                sx={{ pb: 2, mb: 0, borderColor: 'divider' }}
            >
                Upitnik za sistematski pregled
            </Typography> */}


            <Grid container spacing={0} xs={12} sx={{ mt: 3, width: '100%', justifyContent: 'center' }}>
                <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                    <Button sx={{
                        backgroundColor: Colors.MMYellow1, color: 'black', border: '1px solid lightgrey', mx: 5, maxWidth: '200px'
                        , '&:hover': {
                            backgroundColor: 'black',
                            borderColor: 'gray',
                            color: 'white',
                        },
                    }} type="submit" variant="contained" fullWidth onClick={async () => {
                        
                        await handleStartNewAssesment()
                        dispatch(surveySlice.actions.setCurrentPage(1))
                    }}>
                        ZAPOCNI NOVU
                    </Button>
                </Grid>
                <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>

                    <TableContainer component={Paper} sx={{ width: 500, maxHeight: 300 }}>
                        <Table aria-label="simple table" size="small"
                            sx={{
                                '& .MuiTableCell-root': {
                                    padding: '1px 8px',
                                },
                            }}
                        >
                            <TableHead>
                                <TableCell sx={{ fontWeight: 'bold' }}>Zapoceta</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Zavrsena</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                            </TableHead>
                            <TableBody>
                                {healthAssesments.map((ha) => {
                                    const startedOn = ha.startedOn ? new Date(ha.startedOn).toLocaleDateString() : 'NA'
                                    const completedOn = ha.completedOn ? new Date(ha.completedOn).toLocaleDateString() : 'NA'
                                    return (
                                        <TableRow
                                            key={ha.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {startedOn === '1/1/1' ? 'NA' : startedOn}
                                            </TableCell>
                                            <TableCell>
                                                {/* {completedOn === '1/1/1' ? 'NA' : completedOn} */}
                                                <Box display="flex" alignItems="center">
                                                    {/* Progress Bar */}
                                                    <Box width="100%" mr={1}>
                                                        <LinearProgress variant="determinate" value={90} />
                                                    </Box>
                                                    {/* Number Label */}
                                                    <Box minWidth={35}>
                                                        <Typography variant="body2" color="textSecondary">{`${Math.round(90)}%`}</Typography>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{renderContinueButton(ha)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    {/* <Button sx={{
                        backgroundColor: Colors.MMYellow1, color: 'black', border: '1px solid lightgrey', mx: 5
                        , '&:hover': {
                            backgroundColor: 'black',
                            borderColor: 'gray',
                            color: 'white',
                        },
                    }}
                        disabled={isDisabled}
                        type="submit" variant="contained" fullWidth onClick={() => {
                            dispatch(surveySlice.actions.setCurrentPage(1))
                            handleContinueWithLatestHealthAssesment()
                        }}>
                        NASTAVI
                    </Button> */}
                </Grid>
            </Grid>

            {/* Snackbar in Top Center */}
            <Snackbar
                open={open}
                // autoHideDuration={10000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Positioning at top center
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{
                        width: '100%',
                        maxWidth: '400px',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        // border: '1px dotted lightgreen'
                    }}
                >
                    Čestitamo! Uspešno ste popunili naš zdravstveni upitnik. Vaši odgovori se obrađuju. Nakon završene obrade, na našem portalu dobićete preporuku za Sistematski Pregled, personalizovanu za Vas. O završetku obrade obavestićemo Vas putem mail adrese koju ste uneli prilikom registracije.
                </Alert>
            </Snackbar>

        </Box>

    );
}
