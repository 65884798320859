import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IQuestionGroup } from "../../components/survey/survey-question-groups";
import { IAnswer } from "../../components/survey/survey-questions/answers/answer";
import { IHealthAssesment } from "../../components/survey/survey-start/survey-start";
import { IQuestion } from "../../components/survey/survey-questions/survey-questions";
import { AccessTime } from "@mui/icons-material";
import { IHealthExamination } from "../../components/medical-checkup/report/report";
// import { IUserData } from "../../components/survey/survey-form/survey-form";
// import { fetchQuestionGroups } from "./survey-actions";

const initialState: ISurveyState = {
    QuestionGroups: [],
    Questions: [],
    Answers: [],
    HealthAssesment: {
        id: null,
        userId: null,
        startedOn: null,
        completedOn: null
    },
    AllHealthAssessments: [],
    SnackbarStartPage: false,
    SnackbarEndPage: false,
    CurrentPage: 0,
    AllowNext: -1,
    HealthExaminations: []
    // UserData: undefined
}

type ISurveyState = {
    QuestionGroups: IQuestionGroup[],
    Questions: IQuestion[],
    Answers: IAnswer[],
    HealthAssesment: IHealthAssesment,
    AllHealthAssessments: IHealthAssesment[],
    SnackbarStartPage: boolean,
    SnackbarEndPage: boolean,
    CurrentPage: number,
    AllowNext: number,
    HealthExaminations: IHealthExamination[]
    // UserData: IUserData | undefined
}

const surveySlice = createSlice({
    name: "survey",
    initialState,
    reducers: {
        setSnackbarStartPage: (state, action: PayloadAction<boolean>) => {
            state.SnackbarStartPage = action.payload;
        },
        setSnackbarEndPage: (state, action: PayloadAction<boolean>) => {
            state.SnackbarEndPage = action.payload;
        },
        setQuestionGroups: (state, action: PayloadAction<IQuestionGroup[]>) => {
            state.QuestionGroups = action.payload.map((qg, i) => {
                const allQuestions = state.Questions.filter(q => q.questionGroupId == qg.id)

                if (allQuestions.length === allQuestions.filter(q => q.isAnswered).length && allQuestions.length != 0) {
                    return state.QuestionGroups[i] = { ...qg, isAnswered: true }
                } else {
                    return state.QuestionGroups[i] = { ...qg, isAnswered: false }
                }
            });

        },
        setQuestionGroupAnswered: (state, action: PayloadAction<IQuestionGroup>) => {
            // console.log('q true')
            // console.log('q true')
            const questionGroup = action.payload
            const index = state.QuestionGroups.findIndex(x => x.id === questionGroup.id)
            const possibleQuestions = state.Questions.filter(q => q.questionGroupId === questionGroup.id)
            if (possibleQuestions.length === possibleQuestions.filter(x => x.isAnswered).length) {
                state.QuestionGroups[index] = { ...state.QuestionGroups[index], isAnswered: true }
            } else {
                state.QuestionGroups[index] = { ...state.QuestionGroups[index], isAnswered: false }
            }

        },
        setOpenQuestionGroup: (state, action: PayloadAction<IQuestionGroup>) => {
            const index = state.QuestionGroups.findIndex(x => x.id === action.payload.id)
            if (index !== -1) {
                // state.QuestionGroups = state.QuestionGroups.map(x => { return { ...x, isOpen: false } })
                state.QuestionGroups[index].isOpen = action.payload.isOpen
            }
        },
        setQuestions: (state, action: PayloadAction<IQuestion[]>) => {
            state.Questions = action.payload.map((question: IQuestion) => {
                const possibleAnswers = state.Answers.filter(
                    (a) => a.questionId === question.id && a.parentId === 0
                );
                const possibleSubAnswers = state.Answers.filter(
                    (a) => a.questionId === question.id && a.parentId !== 0
                );

                let isAnswered = false;

                if (question.isMultipleSelect) {
                    if (possibleAnswers.filter((a) => a.isSelected).length === 1) {
                        isAnswered = true;

                        if (possibleSubAnswers.length > 0 && possibleSubAnswers.filter((a) => a.isSelected).length == 0) {
                            const answerWithSubAnswersIsSelected = possibleAnswers.findIndex(x => x.id === possibleSubAnswers[0].parentId && x.isSelected)
                            if (answerWithSubAnswersIsSelected != -1 && possibleSubAnswers.filter((a) => a.isSelected).length == 0) {
                                isAnswered = false;
                            }
                        }
                    }
                } else {
                    if (possibleAnswers.filter((a) => a.isSelected).length > 0) {
                        isAnswered = true;

                        if (possibleSubAnswers.length > 0 && possibleSubAnswers.filter((a) => a.isSelected).length == 0) {
                            const answerWithSubAnswersIsSelected = possibleAnswers.findIndex(x => x.id === possibleSubAnswers[0].parentId && x.isSelected)
                            if (answerWithSubAnswersIsSelected != -1 && possibleSubAnswers.filter((a) => a.isSelected).length == 0) {
                                isAnswered = false;
                            }
                        }
                    }
                }
                return { ...question, isAnswered: isAnswered };
            });
        },

        setQuestion: (state, action: PayloadAction<{ questionId: number, isSavedInDB: boolean }>) => {
            const updatedQuestion = action.payload;
            const index = state.Questions.findIndex(q => q.id === updatedQuestion.questionId);

            if (index !== -1) {
                state.Questions[index] = { ...state.Questions[index], isSavedInDB: updatedQuestion.isSavedInDB };

                const possibleAnswers = state.Answers.filter(a => a.questionId === updatedQuestion.questionId && a.parentId === 0)
                const possibleSubAnswers = state.Answers.filter(a => a.questionId === updatedQuestion.questionId && a.parentId !== 0)
                const question = state.Questions[index]

                let isAnswered = false;

                if (question.isMultipleSelect) {
                    if (possibleAnswers.filter((a) => a.isSelected).length === 1) {
                        isAnswered = true;

                        if (possibleSubAnswers.length > 0 && possibleSubAnswers.filter((a) => a.isSelected).length == 0) {
                            const answerWithSubAnswersIsSelected = possibleAnswers.findIndex(x => x.id === possibleSubAnswers[0].parentId && x.isSelected)
                            if (answerWithSubAnswersIsSelected != -1 && possibleSubAnswers.filter((a) => a.isSelected).length == 0) {
                                isAnswered = false;
                            }
                        }
                    }
                } else {
                    if (possibleAnswers.filter((a) => a.isSelected).length > 0) {
                        isAnswered = true;

                        if (possibleSubAnswers.length > 0 && possibleSubAnswers.filter((a) => a.isSelected).length == 0) {
                            const answerWithSubAnswersIsSelected = possibleAnswers.findIndex(x => x.id === possibleSubAnswers[0].parentId && x.isSelected)
                            if (answerWithSubAnswersIsSelected != -1 && possibleSubAnswers.filter((a) => a.isSelected).length == 0) {
                                isAnswered = false;
                            }
                        }
                    }
                }

                state.Questions[index] = { ...question, isAnswered: isAnswered };

                // const qg = state.QuestionGroups.find(qg => qg.id === question.questionGroupId)
                // if(qg !== undefined){
                //     console.log("err")
                //     surveyActions.setQuestionGroupAnswered(qg);
                // } 
                // console.log('question', state.Questions[index])
            }

        },
        setQuestionError: (state, action: PayloadAction<{ questionId: number, errorMsg: string | undefined }>) => {
            const questionErr = action.payload
            const index = state.Questions.findIndex(q => q.id === questionErr.questionId);

            if (index !== -1) {
                state.Questions[index] = { ...state.Questions[index], error: questionErr.errorMsg };
            }
        },
        setAnswers: (state, action: PayloadAction<IAnswer[]>) => {
            state.Answers = action.payload
        },
        setSubAnswer: (state, action: PayloadAction<IAnswer>) => {
            const subanswer = action.payload
            const index = state.Answers.findIndex(x => x.id === subanswer.id)
            if (index != -1) {
                // console.log({ ...subanswer, isSelected: !state.Answers[index].isSelected }.isSelected)
                state.Answers[index] = { ...subanswer, isSelected: !state.Answers[index].isSelected }

            }

        },
        setAnswerSlider: (state, action: PayloadAction<IAnswer>) => {
            const updatedAnswer = action.payload;

            const indexA = state.Answers.findIndex(x => x.id == updatedAnswer.id)
            const indexQ = state.Questions.findIndex(x => x.id === updatedAnswer.questionId)

            state.Answers = state.Answers.map(answer => {
                if (answer.questionId === updatedAnswer.questionId) {
                    return {
                        ...answer,
                        isSelected: false,
                        isAnswered: false
                    };
                }
                return answer;
            });

            // console.log( state.Answers.filter(x => x.questionId == indexQ))
            state.Answers[indexA] = { ...updatedAnswer, isSelected: !updatedAnswer.isSelected, isAnswered: true }

        },
        setAnswer: (state, action: PayloadAction<IAnswer>) => {

            const updatedAnswer = action.payload;
            const answerIndex = state.Answers.findIndex(a => a.id === updatedAnswer.id)
            // console.log('updatedAnswer', updatedAnswer)
            const questionIndex = state.Questions.findIndex(q => q.id === updatedAnswer.questionId)
            const question = state.Questions[questionIndex]

            const allAnswers = state.Answers.filter(a => a.questionId === question.id)

            if (question.maxSelect === 1) {
                //set all on isSelected False
                state.Answers = state.Answers.map((a) => {
                    return a.questionId === question.id ? { ...a, isSelected: false } : a
                })
                //set answer to isSelected true
                state.Answers[answerIndex] = { ...updatedAnswer, isSelected: !updatedAnswer.isSelected }

            } else if (allAnswers.filter(a => a.isSelected).length < question.maxSelect) {
                state.Answers[answerIndex] = { ...updatedAnswer, isSelected: !updatedAnswer.isSelected }
            } else {
                state.Answers[answerIndex] = { ...updatedAnswer, isSelected: false }
            }



        },
        setAnswerSimple: (state, action: PayloadAction<IAnswer>) => {
            state.Answers[state.Answers.findIndex(x => x.id === action.payload.id)] = action.payload
            // console.log("answer", state.Answers[state.Answers.findIndex(x => x.id === action.payload.id)])
        },
        setHealthAssesment: (state, action: PayloadAction<IHealthAssesment>) => {
            state.HealthAssesment = action.payload
        },
        setAllHealthAssesments: (state, action: PayloadAction<IHealthAssesment[]>) => {
            state.AllHealthAssessments = action.payload
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.CurrentPage = action.payload
        },
        setHealthExamination: (state, action: PayloadAction<IHealthExamination[]>) => {
            state.HealthExaminations = action.payload
        },
        setAllowNext: (state, action: PayloadAction<boolean>) => {
            const allowNext = action.payload
            if(allowNext){
                state.AllowNext = state.CurrentPage
            } else {
                
                state.AllowNext = -1
            }
            
        }
        // setUserData: (state, action: PayloadAction<IUserData>) => {
        //     state.UserData = action.payload
        // }
    }
});

export const surveyActions = surveySlice.actions;
export default surveySlice;