import { Dispatch } from "react";
import { Action } from "@reduxjs/toolkit";
import medicalCheckupSlice, { medicalCheckupActions } from "./checkup-slice";

export const fetchHealthAssessmentScores = (fetchExecute: any, healthAssesmentId:number) => {
    return(async (dispatch: Dispatch<Action>) => {
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Query/GetHealthAssessmentScores/${healthAssesmentId}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            const data = await response.json()
            dispatch(medicalCheckupActions.setHealthAssessmentScores(data.Data))
            // console.log(data)
        } catch (error) {
            
        }
    });
}