

export enum Colors {
    MMYellow = 'rgba(255,255,255,255)',
    // MMYellow1 = 'rgba(241,237,233,255)',
    MMYellow1 = 'white',
    MMYellow2lighter = 'rgb(253, 251, 248)',
    // MMYellow2lighter = 'white',
    SuccessColor = 'rgba(220,240,237,255)', //aquamarine
    DarkGreen = 'rgba(88, 163, 153, 1)',
    DarkerGreen = 'rgba(66, 122, 115, 1)'
}

export enum Sizes {
    BreakPoint = 997
}

export enum Genders {
    male = 'male',
    female = 'female'
}

export const QuestionCounter = (() => {
    let value = 0; 
  
    return {
      getValue: (): number => value, 
      increment: (): number => ++value, 
    };
  })();