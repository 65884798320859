
// type Orientation = "vertical" | "horizontal";



import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import * as d3 from "d3";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { fetchHealthAssessmentScores } from "../../../store/medical-checkup-page/checkup-actions";
import { Colors } from "../../../constants/constants";

interface BarChartProps {
  // data: { category: string; totalAnswers: number }[];
  // orientation: Orientation;
}

export interface IHealthAssesmentScores {
  id: number,
  healthAssessmentId: number,
  healthAssessment: string,
  score: number,
  description: string
}

const D3BarChart: React.FC = () => {
  const chartRef = useRef<SVGSVGElement | null>(null);
  const dispatch: any = useDispatch();
  const healthAssessmentScores = useSelector((state: RootState) => state.medicalCheckup.healthAssessmentScores);
  const healthAssessment = useSelector((state: RootState) => state.survey.HealthAssesment);

  useEffect(()=>{ dispatch(fetchHealthAssessmentScores(fetch, healthAssessment.id ?? 0));},[])

  useEffect(() => {
   

    const svgWidth = chartRef.current?.parentElement?.clientWidth || 800;
    const svgHeight = 400;

    d3.select(chartRef.current).selectAll("*").remove();

    const margin = { top: 30, right: 10, bottom: 120, left: 100 };
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    const sortedData = [...healthAssessmentScores].sort((a, b) => b.score - a.score);

    const svg = d3
      .select(chartRef.current)
      .attr("width", svgWidth)
      .attr("height", svgHeight)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleBand()
      .domain(sortedData.map((has) => has.healthAssessment))
      .range([0, width])
      .padding(0.2);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(sortedData, (d) => d.score) || 100])
      .nice()
      .range([height, 0]);

    const getColor = (value: number): string => {
      // if (value >= 75) return "#4caf50";
      // if (value >= 50) return "#ffeb3b";
      // if (value >= 25) return "#ff9800";
      return Colors.DarkerGreen;
    };

    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    svg.append("g").call(d3.axisLeft(y));

    svg
      .selectAll("rect")
      .data(sortedData)
      .enter()
      .append("rect")
      .attr("x", (has) => x(has.healthAssessment) || 0)
      .attr("y", (has) => y(has.score))
      .attr("width", x.bandwidth())
      .attr("height", (has) => height - y(has.score))
      .attr("fill", (has) => getColor(has.score));
  }, [dispatch, healthAssessment.id, healthAssessmentScores]);

  return <svg ref={chartRef} />;
};

const StackedBarChart = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowX: "auto",
          width: "80vw",
          maxWidth: "100%",
        }}
      >
        <Box sx={{ width: "400px" }}>
          <D3BarChart />
        </Box>
      </Box>
    </Box>
  );
};

export default StackedBarChart;



