import { Box, Typography, Button, TextField, Grid, Paper, Snackbar, Alert } from "@mui/material";
import { IAnswer, IAnswerSelectionType, ISubmitAnswersModel } from "../survey-questions/answers/answer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IQuestion } from "../survey-questions/survey-questions";
import { useEffect, useState } from "react"; // Import useState for managing the snackbar state
import { Colors } from "../../../constants/constants";
import surveySlice from "../../../store/survey/survey-slice";
import { Link } from "react-router-dom";
import { CompleteSurvey, fetchQuestions, SubmitAnswersInDatabase } from "../../../store/survey/survey-actions";
import { IHealthAssesment } from "../survey-start/survey-start";

export interface ISurveyEndProps { }

export const SurveyEnd: React.FunctionComponent<ISurveyEndProps> = (props) => {
    // const [open, setOpen] = useState(false); // State to control Snackbar visibility
    const open = useSelector((state: RootState) => state.survey.SnackbarEndPage)
    const questionGroups = useSelector((state: RootState) => state.survey.QuestionGroups)
    const questions = useSelector((state: RootState) => state.survey.Questions)

    const dispatch: any = useDispatch();
    const [zakljucakContent, setZakljucak] = useState<string>('')

    const zakljucak: IQuestion | undefined = useSelector((state: RootState) => state.survey.Questions)
        .find(x => x.id == 96);
    const answer = useSelector((state: RootState) => state.survey.Answers).find(x => x.questionId === zakljucak?.id)

    const healthAssesment: IHealthAssesment = useSelector((state: RootState) => state.survey.HealthAssesment)


    const cansubmitFunc = () => {
        const numberOfQuestionGroups = questionGroups.filter(qg => qg.inLevel === 2).length;
        const numberOfAnsweredGroups = questionGroups.filter(qg => qg.isAnswered && qg.inLevel === 2).length;
        const condition = numberOfQuestionGroups === numberOfAnsweredGroups;


        return condition;``
    }
    const [canSubmit, setCanSubmit] = useState<boolean>(cansubmitFunc);
   
    const handleSubmit = () => {
        if (canSubmit) {
            //ovde pozvati proceduru koja update [CompletedOn] kolonu 
            if(healthAssesment.id != null){
                dispatch(CompleteSurvey(fetch, healthAssesment.id))
            }
          
            //ovde pozvati Ivanovu proceduru spCalculateHelthAssesmentScore
            dispatch(surveySlice.actions.setSnackbarStartPage(true))
        } 
    };

    //hardcode zakljucak id
    useEffect(() => {
        const answer: IAnswerSelectionType = {
            answerId: 610,
            isSelected: true,
            text: zakljucakContent
        }
        const body: ISubmitAnswersModel = {
            questionId: zakljucak?.id ?? 0,
            healthAssesmentId: healthAssesment?.id ?? 0,
            answers: [answer]
            // additionalComment: zakljucakContent
        }
        const timer = setTimeout(() => {
            dispatch(SubmitAnswersInDatabase(fetch, body));
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [zakljucak])


    // const handleSnackbarEndPage = (): void => {

    //     dispatch(surveySlice.actions.setSnackbarEndPage(true))
    // }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(surveySlice.actions.setSnackbarEndPage(false))
        // setOpen(false);
    };

    return (
        <Box
            sx={{
                minHeight: '80vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'stretch',
                alignItems: 'center',
                textAlign: 'center',
                px: 0,
                height: '80vh',
            }}
        >
            <Grid
                container
                spacing={0}
                sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "stretch",
                    flexGrow: 1,
                    height: '100%',
                }}
            >
                <Grid item sx={{ width: '100%', mb: 0 }}>
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography
                            variant="h4"
                            component="h2"
                            sx={{ pb: 2, mb: 0, borderColor: 'divider' }}
                        >
                            Hvala vam što ste popunili ovu anketu!
                        </Typography>
                    </Box>
                </Grid>

                {/* Middle Grid Item that expands */}
                <Grid
                    item
                    sx={{

                        width: '100%',
                        height: '70%',
                        p: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flexGrow: 1,
                    }}
                >
                    <Box sx={{

                        width: '100%',
                        height: '100%',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Typography
                            variant="body1"
                            component="p"
                            sx={{ pb: 4, maxWidth: '600px', fontWeight: 'bold' }}
                        >
                            {zakljucak?.text}
                        </Typography>

                        <Box sx={{ backgroundColor: 'white', maxWidth: '600px', width: '100%', borderRadius: '5px' }}>
                            <TextField
                                onChange={(e) => setZakljucak(e.target.value)}
                                // value={answer?.additionalComment}
                                label="Vaš komentar"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    width: '100%',
                                    mb: 0
                                }}
                                InputProps={{
                                    style: { border: 'none' },
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item>
                    <Link to={`${!open ? "/6" : "/"}`}>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            disabled={!canSubmit}
                            sx={{
                                backgroundColor: 'black',
                                color: '#fff',
                                padding: '10px 20px',
                                fontSize: '16px',
                                borderRadius: '8px',
                                mb: 1,
                                ':hover': {
                                    backgroundColor: 'black',
                                },
                            }}
                        >
                            SUBMIT (POŠALJI)
                        </Button>
                    </Link>
                    <Typography sx={{ color: 'red' }}>{!canSubmit &&
                        "Postoje polja koja nisu popunjena. Molimo vas da proverite i unesete sve potrebne informacije."}</Typography>
                </Grid>
            </Grid> 
            <Snackbar
                open={open}
                // autoHideDuration={10000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Positioning at top center
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{
                        width: '100%',
                        maxWidth: '400px',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        // border: '1px dotted lightgreen'
                    }}
                >
                    Anketa nije poslana jer postoje polja koja nisu popunjena. Molimo vas da proverite i unesete sve potrebne informacije.
                </Alert>
            </Snackbar>
        </Box>
    );
};
