import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHealthAssesmentScores } from "../../components/medical-checkup/barchart/medical-checkup-barchart";


type IMedicalCheckupState = {
    healthAssessmentScores: IHealthAssesmentScores[]
}

const initialState: IMedicalCheckupState = {
    healthAssessmentScores: []
}

const medicalCheckupSlice = createSlice({
    name: 'medicalCheckup',
    initialState,
    reducers: {
        setHealthAssessmentScores: (state, action: PayloadAction<IHealthAssesmentScores[]>) => {
            state.healthAssessmentScores = action.payload
        }
    }
})

export const medicalCheckupActions = medicalCheckupSlice.actions
export default medicalCheckupSlice
