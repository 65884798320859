import { configureStore } from "@reduxjs/toolkit";
import surveySlice from "./survey/survey-slice";
import generalSlice from "./general/general-slice";
import modalFormSlice from "./login-modal-form/login-modal-form";
import medicalCheckupSlice from "./medical-checkup-page/checkup-slice";

export const store = configureStore({
    reducer: {
        survey: surveySlice.reducer,
        general: generalSlice.reducer,
        loginModal: modalFormSlice.reducer,
        medicalCheckup: medicalCheckupSlice.reducer
        
    }
});

export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;