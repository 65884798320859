import React from 'react';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { Button } from '@mui/material';
import { Colors } from '../../../../constants/constants';
import GoogleIcon from '@mui/icons-material/Google';
import { useDispatch, useSelector } from 'react-redux';
import { surveyActions } from '../../../../store/survey/survey-slice';
import { GoogleAuth } from '../../../../store/login-modal-form/login-modal-form-actions';

const clientId = "149434973482-g554fo1bvf72r359v4isbmjq4hhbm9vv.apps.googleusercontent.com";

export interface IGoogleLoginResponse{
    clientId: string, credential: string
}

const GoogleLoginComponent: React.FC = () => {
    const dispatch: any = useDispatch()
  const handleLoginSuccess = (response: CredentialResponse) => {
    dispatch(GoogleAuth(fetch, response))
    // console.log('Login Success:', response);
    // You can decode the credential if needed
    // Example:
    // const decodedToken = jwt_decode(response.credential!);
    // console.log(decodedToken);
  };

  const handleLoginError = () => {
    console.error('Login Failed');
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div>
        {/* <h3>Sign in with Google</h3> */}
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginError}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginComponent;
