import React from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { IAnswer } from "../../../../survey/survey-questions/answers/answer";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { Colors } from "../../../../../constants/constants";

export interface IAnswersProp {
  questionId: number;
//   questionIndex: number
}

export const Answers: React.FC<IAnswersProp> = ({ questionId}) => {
  const answers: IAnswer[] = useSelector((state: RootState) => state.survey.Answers)
    .filter((a) => a.questionId === questionId);

  return (
    <TableContainer >
      <Table size="small">
        {/* <TableHead>
          <TableRow>
            <TableCell align="left">Index</TableCell>
            <TableCell align="left">Answer</TableCell>
            <TableCell align="center">Selected</TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          {answers.map((answer, index) => {
            // let i = questionIndex; 
            return(
            <TableRow key={index} sx={{border: 'none', color: Colors.DarkerGreen}}>
              {/* <TableCell align="left">{index + 1}</TableCell> */}
              {answer.isSelected && <TableCell  sx={{border: 'none', color: Colors.DarkerGreen, pl: answer.parentId == 0 ? 0 : 2, py:0}}>
                {answer.parentId == 0 ? 
                `+ ${answer.text}` 
                : 
                `- ${answer.text}`}
                
                </TableCell>}
              {/* <TableCell align="center">{answer.isSelected ? "Yes" : "No"}</TableCell> */}
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
