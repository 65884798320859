import izvestaj from '../../../assets/question-groups/Izvestaji.jpg'

import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, Modal, Typography, Box, TableHead, List } from '@mui/material';
import { Colors } from '../../../constants/constants';
import { IHealthAssesmentScores } from '../barchart/medical-checkup-barchart';
import Image from 'mui-image'

// Define the interface for the report table rows
export interface IHealthExamination {
    id: number;
    name: string;
    isChecked: boolean;
}

// Props interface for ReportTable component
interface ReportTableProps {
    data: IHealthAssesmentScores[];
}

// Mapping reportName to tests and categories
const reportDetails = {
    Opsti: {
        tests: "Kompletna krvna slika, Analiza urina",
        categories: "Svi korisnici"
    },
    KV: {
        tests: "Masnoce u krvi, EKG, Ultrazvuk srca, Test opterecenja",
        categories: "Muskarci 40 do 49, Muskarci 50+, Zene 50+, Rizik od KVS"
    },
    Probava: {
        tests: "Test krv u stolici, Okultno krvarenje, Kolonoskopija, KKS sa sedimentacijom",
        categories: "Muskarci 50+, Zene 50+"
    }
};

const ReportTable: React.FC<ReportTableProps> = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState<string | null>(null);
    // console.log('data', data)
    const handleOpen = (reportName: string) => {
        setSelectedReport(reportName);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedReport(null);
    };

    return (
        <div style={{ height: '100%', width: '100%' }} >
            <TableContainer style={{ height: '100%', overflow: 'auto', }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow >
                            <TableCell sx={{ backgroundColor: Colors.DarkGreen, color: 'white' }}>PREGLED</TableCell>
                            <TableCell sx={{ backgroundColor: Colors.DarkGreen, color: 'white' }}>DETALJI</TableCell>
                            {/* <TableCell >UKUPNI BODOVI</TableCell>
                            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id} sx={{ backgroundColor: 'white' }}>
                                {/* <TableCell sx={{ fontWeight: 'bold' }}>{row.name}</TableCell> */}
                                <TableCell sx={{ backgroundColor: 'white' }}>
                                    {/* <Button
                                        variant="contained"

                                        // color="white"
                                        // disabled={!row.isChecked}
                                        onClick={() => handleOpen(row.healthAssessment)}
                                        sx={{
                                            backgroundColor: Colors.DarkGreen,
                                            width: '100%',
                                            p:0.25,
                                            m:0,
                                            // all: 'unset', // Removes all default button styles
                                            cursor: 'pointer', // Adds a pointer cursor for interactivity
                                            // color: row.isChecked ? 'green' : 'red', // Sets text color based on condition
                                            // textDecoration: 'underline', // Optional: makes it look like a link
                                            '&:hover': {
                                                // textDecoration: 'none', // Removes underline on hover for a subtle effect
                                                backgroundColor: Colors.DarkerGreen
                                            },
                                        }}
                                    > */}
                                    {/* {row.healthAssessment} */}
                                    <Table sx={{
                                        border: 'none', backgroundColor: Colors.DarkGreen, '&:hover': {
                                            // textDecoration: 'none', // Removes underline on hover for a subtle effect
                                            backgroundColor: Colors.DarkerGreen
                                        },
                                    }}>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell sx={{ width: '60px' }}>
                                                    <Image
                                                        width={60}
                                                        height={60}
                                                        src={izvestaj}
                                                    />
                                                </TableCell>
                                                <TableCell align='left' sx={{ color: 'white' }}>
                                                    {row.healthAssessment}
                                                </TableCell>
                                                {/* <TableCell align='right' sx={{ border: 'none' }}>
                                                        {row.score}
                                                    </TableCell> */}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    {/* </Button> */}
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold', whiteSpace: 'normal' }}>

                                    {data[0].healthAssessment === 'KARDIOLOSKI' ?
                                        <Box><Typography variant="h6" gutterBottom>
                                            KARDIOLOŠKI PREGLED - MUŠKARCI
                                        </Typography>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Heath Screening Assessment - Upitnik
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Laboratorijske analize:
                                            </Typography>
                                            <List dense>
                                                <Typography component="li">
                                                    Kompletna krvna slika, gvožđe, sedimentacija, glikemija, holesterol (LDL, HDL), trigliceridi, urea, kreatinin, ALT, AST, urin kompletan, ALP, TSH i T4
                                                    (PSA kod muškaraca preko 40 godina)
                                                </Typography>
                                                <Typography component="li">Internistički pregled sa EKG-om</Typography>
                                                <Typography component="li">Pregled urologa sa ultrazvučnim pregledom</Typography>
                                                <Typography component="li">Ultrazvuk štitne žlezde</Typography>
                                                <Typography component="li">Ultrazvuk abdomena</Typography>
                                                <Typography component="li">Dermatolog sa dermatoskopijom</Typography>
                                                <Typography component="li">Završni pregled</Typography>
                                            </List>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>DODATNE ANALIZE:</strong>
                                            </Typography>
                                            <List dense>
                                                <Typography component="li">
                                                    Dodatne laboratorijske analize: hs-CRP, NT-proBNP ili BNP, Lipoprotein
                                                </Typography>
                                                <Typography component="li">Ultrazvuk srca</Typography>
                                                <Typography component="li">Dopler krvnih sudova (40+)</Typography>
                                            </List></Box>
                                        :
                                        <Box><TableCell sx={{ fontWeight: 'bold', whiteSpace: 'normal' }}>
                                            <Typography variant="h6" gutterBottom>
                                                METABOLIČKI PREGLED ZA PREVENCIJU DIJABETESA I METABOLIČKOG SINDROMA - MUŠKARCI
                                            </Typography>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Heath Screening Assessment - Upitnik
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Laboratorijske analize:
                                            </Typography>
                                            <List dense>
                                                <Typography component="li">
                                                    Kompletna krvna slika, gvožđe, sedimentacija, glikemija, holesterol (LDL, HDL), trigliceridi, urea, kreatinin, ALT, AST, urin kompletan, ALP, TSH i T4
                                                    (PSA kod muškaraca preko 40 godina)
                                                </Typography>
                                                <Typography component="li">Internistički pregled sa EKG-om</Typography>
                                                <Typography component="li">Pregled urologa sa ultrazvučnim pregledom</Typography>
                                                <Typography component="li">Ultrazvuk štitne žlezde</Typography>
                                                <Typography component="li">Ultrazvuk abdomena</Typography>
                                                <Typography component="li">Dermatolog sa dermatoskopijom</Typography>
                                                <Typography component="li">Završni pregled</Typography>
                                            </List>
                                            <Typography variant="body1" gutterBottom>
                                                <strong>DODATNE ANALIZE:</strong>
                                            </Typography>
                                            <List dense>
                                                <Typography component="li">HbA1c (glikozilirani hemoglobin)</Typography>
                                                <Typography component="li">Insulin na prazan stomak sa HOMA-IR indeksom</Typography>
                                            </List>
                                        </TableCell>
                                        </Box>
                                    }

                                </TableCell>

                                {/* <TableCell sx={{ fontWeight: 'bold' }}>{row.score}</TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Paper style={{ margin: 10, padding: 10 }}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Pregled: {selectedReport}
                        </Typography>
                        {selectedReport && selectedReport in reportDetails && (
                            <>
                                <Typography id="modal-description" sx={{ mt: 2 }}>
                                    <strong>Testovi:</strong> {reportDetails[selectedReport as keyof typeof reportDetails].tests}
                                </Typography>
                                {/* <Typography id="modal-categories" sx={{ mt: 2 }}>
                                <strong>Kategorije:</strong> {reportDetails[selectedReport as keyof typeof reportDetails].categories}
                            </Typography> */}
                            </>
                        )}
                        <Button variant="contained" onClick={handleClose} sx={{ mt: 2, backgroundColor: 'lightgreen' }}>
                            Zatvori
                        </Button>
                    </Paper>
                </Modal>
            </TableContainer>

            {/* Modal Component */}

        </div>
    );
};



export default ReportTable;
