import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IQuestionGroup } from "../../survey/survey-question-groups";
import { Questions } from "./questions/questions";
import { Colors } from "../../../constants/constants";

export const NewSurvey: React.FC = () => {
    const questionGroups: IQuestionGroup[] = useSelector(
        (state: RootState) => state.survey.QuestionGroups
    );

    return (
        <TableContainer component={Paper} sx={{ marginTop: 2, borderRadius: 0 }}>
            <Table size="small">
                <TableHead sx={{ backgroundColor: Colors.DarkGreen }}>
                    <TableRow>
                        <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                            Kategorija
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                            Rezultati
                        </TableCell>
                        {/* <TableCell sx={{ fontWeight: "bold", color: "black" }}>
              Questions and Answers
            </TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...questionGroups] // Create a shallow copy of the array to avoid mutating the original
                        .filter(qg => qg.inLevel === 2)
                        .sort((a, b) => (a.orderno ?? 0) - (b.orderno ?? 0)) // Sort safely
                        .map((qg, i) => (
                            <TableRow key={qg.id}>
                                <TableCell sx={{ backgroundColor: Colors.SuccessColor, color: Colors.DarkerGreen, py:0 }}>{qg.name || "No Name"}</TableCell>
                                {/* <TableCell sx={{borderRight: '1px solid lightgrey'}}>{qg.orderno}</TableCell> */}
                                <TableCell sx={{py:0}}>
                                    {/* Render the Questions component for each Question Group */}
                                    <Questions questionGroupId={qg.id ?? 0} qgIndex={i}/>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
