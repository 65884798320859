import { Card, Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import NavbarMain from '../components/navbar/navbar';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import backgroundImage from '../assets/question-groups/v870-tang-36.jpg';

interface ILayoutPage {
  children?: React.ReactNode;
}
export const LayoutPage: React.FunctionComponent<ILayoutPage> = props => {
  const browserWidth = useSelector((state: RootState) => state.general.browserWidth);
  let boxWidth = {};
  if (browserWidth !== undefined && browserWidth > 768) boxWidth = { width: '75%' };
  if (browserWidth !== undefined && browserWidth < 768) boxWidth = { width: '100%' };

  return (
    <div
      style={{
        position: 'relative',
        minHeight: '100vh',
      }}
    >
      {/* Background Image with Blur Overlay */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          filter: 'blur(0px)', 
          zIndex: -1, 
        }}
      ></div>

      {/* Dark Overlay */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.25)', 
          zIndex: -1, 
        }}
      ></div>

      <NavbarMain />
      <Container className="h-100" style={{ minHeight: '75%', backgroundColor: 'transparent' }}>
        <Card className="border-0 d-flex justify-content-center" style={{ backgroundColor: 'transparent' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Outlet />
          </Box>
        </Card>
      </Container>
    </div>
  );
};
