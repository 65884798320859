import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

interface IExamDetailsProp{
    data: string
}

export const ExamDetails: React.FC<IExamDetailsProp> = ({data}) => {
    const [dataAsList, setDataAsList] = useState<
    { title: string; details: string[] }[]
  >([]);

  useEffect(() => {
    const parseData = (input: string) => {
      const sections = input.split(/(?=\bDODATNE ANALIZE\b)/); 
      const parsedSections = sections.map((section) => {
        const [title, ...details] = section
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line); 
        return { title, details };
      });
      return parsedSections;
    };

    setDataAsList(parseData(data));
  }, [data]);
  return (
    <Box>
      {dataAsList.map((section, index) => (
        <Box key={index} mb={4}>
          <Typography variant="h5" gutterBottom>
            {section.title}
          </Typography>
          <Typography variant="body2">
          <List dense>
            {section.details.map((detail, idx) => (
              <ListItem key={idx}>
                <ListItemText primary={detail} />
              </ListItem>
            ))}
          </List>
          </Typography>
        </Box>
      ))}
    </Box>
  );
};