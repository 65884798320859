import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { IQuestion } from "../../../survey/survey-questions/survey-questions";
import { Answer } from "../../../survey/survey-questions/answers/answer";
import { Colors } from "../../../../constants/constants";
import { Answers } from "./answers/answers";

interface IQuestionsProp {
    questionGroupId: number,
    qgIndex: number
}

export const Questions: React.FC<IQuestionsProp> = ({ questionGroupId, qgIndex }) => {
    const questions: IQuestion[] = useSelector(
        (state: RootState) => state.survey.Questions
    ).filter(x => x.questionGroupId === questionGroupId);

    return (
        <TableContainer  sx={{ marginTop: 0}}>
            <Table size="small">
                {/* <TableHead>
                    <TableRow>
               
                        <TableCell sx={{ fontWeight: "bold", color: "black" }}>
                            Pitanje
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "black" }}>
                            Odgovor
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "black" }}>
                            Is Answered
                        </TableCell>
                    </TableRow>
                </TableHead> */}
                <TableBody >
                    {questions.map((question, i) => {
                        // let index = qgIndex
                        return(
                        <TableRow key={question.id} sx={{border: 'none'}}>
                            {/* <TableCell>{question.id}</TableCell> */}
                            <TableCell sx={{color: Colors.DarkerGreen, border: 'none', py:0, display: 'flex', justifyContent: 'left'}}>{question.orderNo}. {question.text || "N/A"}</TableCell>
                            <TableCell sx={{ width: '25%', p:0, border: 'none', py:0 }}><Answers questionId={question.id ?? 0} /></TableCell>
                            {/* <TableCell>{question.isAnswered ? "Yes" : "No"}</TableCell> */}
                        </TableRow>
                    )})}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
