
import { Dispatch } from 'react'
import { Action } from 'redux' 
import {  IModal, setUserData } from './login-modal-form';
import { IUser } from '../../components/survey/survey-form/survey-form';
import { dispatch } from 'd3';
import { CredentialResponse } from '@react-oauth/google';

// import { Action } from "@reduxjs/toolkit";


export const RegisterUser = (fetchExecute: any, email: string, pwd: string) => {
    return(async (dispatch: Dispatch<Action>)=>{
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/RegisterUser`,{
                method: 'POST',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    Email: email,
                    Pwd: pwd
                })
            })
            const data = await response.json();
            // console.log(data)
        } catch (error) {
            console.error('RegisterUser',error)
        }
    });
}

export const LoginUser = (fetchExecute: any, email: string, pwd: string) => {
    return(async (dispatch: Dispatch<Action>) => {
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/LoginUser`,
                {
                    method: 'POST',
                    headers: {'Content-Type':'application/json'},
                    body: JSON.stringify({
                        Email: email, Pwd: pwd
                    }),
                    credentials: 'include'
                }
                
            )
            const data = await response.json();
            // console.log(data)
            const res: IModal = {IsAuthenticated: true, userData:data.Data }
            dispatch(setUserData(res))
        } catch (error) {
            // console.log("LoginUser",error)
            dispatch(setUserData({IsAuthenticated:false, userData:{}}))
        }
    });

    
}

export const CookieLoginUser = (fetchExecute: any) => {
    return(async(dispatch: Dispatch<Action>)=>{
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/CookieLoginUser`,{
                method: 'POST',
                headers: {'Content-Type':'application/json'},
                credentials: 'include'
            });
            const data = await response.json();
            const res: IModal = {IsAuthenticated: true, userData: data.Data}
            dispatch(setUserData(res));
            // console.log("CookieLoginUser",res)
        } catch (error) {
            console.error("CookieLoginUser",error)
            dispatch(setUserData({IsAuthenticated: false, userData: {}}));
        }
    });
}

export const GoogleAuth = (fetchExecute: any, data: CredentialResponse) => {
    return (async (dispatch: Dispatch<Action>)=>{

        const x = {
            ClientId: data.clientId,
            Credential: data.credential
        }
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/GoogleLogin`,{
                method: 'POST',
                headers: {'Content-Type':'application/json'},
                credentials: 'include',
                body: JSON.stringify(x),
                
            })
            const data = await response.json();
            // console.log("data", data.Data)
            const res: IModal = {IsAuthenticated: true, userData: data.Data}
            // console.log("data", res)
            dispatch(setUserData(res));
        } catch (error) {
            console.log(error)
            dispatch(setUserData({IsAuthenticated:false, userData:{}}))
        }
    });
}

export const UpdateUserData = (fetchExecute: any, userData: IUser, healthAssessmentId: number | null) => {
    const body = {
        // cardiovascular: userData.cardiovascular,
        // diabetes: userData.diabetes,
        // cancer: userData.cancer,
        // cancerType: userData.cancerType,
        // highBloodPressure: userData.highBloodPressure,
        // other: userData.other,
        // otherConditions: userData.otherConditions,
        // mentalIllness: userData.mentalIllness,
        id: userData.id, 
        firstName: userData.firstName,
        lastName: userData.lastName,
        dateOfBirth: userData.dateOfBirth,
        gender: userData.gender,
        // phoneNumber: userData.phoneNumber,
        email: userData.email,
        height: Number(userData.height),
        weight: Number(userData.weight),
        healthAssessmentId: healthAssessmentId

    };
    return (async (dispatch: Dispatch<Action>)=>{
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/UpdateUserData`,{
                method: 'PUT',
                headers: {'Content-Type':'application/json'},
                credentials: 'include',
                body: JSON.stringify(body)
            })
            const data = await response.json();
            // console.log('UpdateUserData', data)
        } catch (error) {
            console.error('UpdateUserData', error)
        }
    });
}

// export const InsertUpdateFamilyHistory = (fetchExecute: any, userData: IUser) => {
//     const body = {
//         cardiovascular: userData.cardiovascular,
//         diabetes: userData.diabetes,
//         cancer: userData.cancer,
//         cancerType: userData.cancerType,
//         highBloodPressure: userData.highBloodPressure,
//         other: userData.other,
//         otherConditions: userData.otherConditions,
//         mentalIllness: userData.mentalIllness,
//         id: userData.id, 
//         // firstName: userData.firstName,
//         // lastName: userData.lastName,
//         // dateOfBirth: userData.dateOfBirth,
//         // gender: userData.gender,
//         // phoneNumber: userData.phoneNumber,
//         // email: userData.email
//     };
//     return(async (dispatch: Dispatch<Action>)=>{
//         try {
//             const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/InsertUpdateFamilyHistory`,{
//                 method: 'PUT',
//                 headers:{'Content-Type':'application/json'},
//                 credentials: 'include',
//                 body: JSON.stringify(userData)
//             })
//             const data = await response.json();
//             // console.log('InsertUpdateFamilyHistory', data)
//         } catch (error) {
//             console.error('InsertUpdateFamilyHistory', error)
//         }
//     });
// }

// export interface IFamilyHistory {
//     id: number;                       // PK, FK, not null
//     cardiovascular?: boolean | null;  // bit, null
//     diabetes?: boolean | null;        // bit, null
//     cancer?: boolean | null;          // bit, null
//     cancerType?: string | null;       // nvarchar(255), null
//     highBloodPressure?: boolean | null; // bit, null
//     other?: boolean | null;           // bit, null
//     otherConditions?: string | null;  // nvarchar(255), null
//     mentalIllnessInFamily?: boolean | null; 
// }



// export const GetFamilyHistory = (fetchExecute: any, id: number) => {
//     return(async(dispatch: Dispatch<Action>)=>{
//         try {
//             const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Query/GetFamilyHistory/${id}`,
//                 {
//                     method: 'GET',
//                     headers:{'Content-Type':'application/json'},
//                     credentials: 'include',
//                 }
//             )

//             const data = await response.json();
//             const res: IFamilyHistory = data.Data
//             // console.log("rezultat", res)
//             dispatch(setFamilyHistory(res))
//         } catch (error) {
            
//         }
//     });
// }